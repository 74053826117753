import React from 'react'

const Footer = () => {
    return (
        <footer>
			<div id="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h2 className="color_dorado_oscuro">Laura Alejandra</h2>
						</div>
						<div className="col-md-6 offset-md-3 text-center">
							<p className="color_dorado_oscuro">Hecho con <i className="icon-heart3"></i> por <a href="https://duitalles.com/" target="_blank" rel="noreferrer">Duitalles</a></p>
						</div>
					</div>
				</div>
			</div>
		</footer>
    )
}

export default Footer;