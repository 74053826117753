import React from 'react';

import { useLocation } from 'react-router-dom';

const Header = () => {
	
	const location = useLocation();

    return (
        <header id="fh5co-header-section" className="sticky-banner">
			<div className="container">
				<div className="nav-header">
					{/* <a href="#" className="js-fh5co-nav-toggle fh5co-nav-toggle dark"><i></i></a> */}
					<a href="/"><img className="logo-header" src="/assets/images/logo-alt.png" alt="" /></a>
					<button id="boton-audio" className="boton-oscuro"><i id="icono-audio" className="fa fa-play-circle" aria-hidden="true"></i></button>
				</div>
			</div>
		</header>
    )
}

export default Header;