import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import { setAlert } from '../../Redux/actions/alert';
import { listarInvitaciones, enviarInvitacion, eliminarInvitacion } from '../../Redux/actions/invitaciones';

const styles = {
    titulo_seccion: {
        fontFamily: 'inherit',
        fontSize: '24px',
        fontWeight: '500'
    },
    contenido_tabla: {
    	fontSize: '16px'
    },
    btn_tabla: {
    	fontFamily: 'inherit',
    	letterSpacing: 'inherit',
    	fontSize: '16px'
    },
    imagen_fila: {
    	maxHeight: '90px'
    },
    badge_boton: {
        cursor: 'pointer'
    }
}

const InvitacionesListado = () => {

	const dispatch = useDispatch();

	const { listado } = useSelector(state => state.invitaciones);

    const [filas, setFilas] = useState([]);
    const [filterText, setFilterText] = useState('');
	const [eliminacionModal, setEliminacionModal] = useState(false);

	const [eliminarData, setEliminarData ] = useState({
        id: null
    });

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Código',
            selector: row => row.codigo,
        },
        {
            name: 'Referencia',
            selector: row => row.referencia,
            wrap: true,
            grow: 2
        },
        {
            name: 'Adultos invitados',
            selector: row => row.adultos?.length,
        },
        {
            name: 'Adultos confirmados',
            selector: row => row.adultos?.filter( adulto => adulto.status == true )?.length,
        },
        {
            name: 'Niños invitados',
            selector: row => row.ninos?.length,
        },
        {
            name: 'Niños confirmados',
            selector: row => row.ninos?.filter( nino => nino.status == true )?.length,
        },
        {
            name: 'Teléfono',
            grow: 1.5,
            selector: row => row.numero ? formatPhoneNumber(row.numero) : '-'
        },
        {
            name: '',
            grow: .8,
            cell: row => {
                return(
                    <div className="text-right w-100">
                        {/* <button type="button" className="btn text-success" style={styles.btn_tabla} onClick={() => handleEnviar(item)} disabled>{item.enviada ? 'Reenviar' : 'Enviar'} <i className="icon-whatsapp"></i></button> */}
                        <button type="button" className="btn btn-sm btn-link text-success px-2 float-end" onClick={() => handleEnviarManual(row)}><i className="icon-whatsapp"></i></button>
                        <Link className="btn btn-sm btn-link text-primary px-2 float-end" to={`/invitaciones_listado/editar/${row._id}`}><i className="icon-pencil"></i></Link>
                        <button type="button" className="btn btn-sm btn-link text-danger px-2 float-end" onClick={() => handleEliminar(row)}><i className="icon-trash"></i></button>
                    </div>
                )
            }
        }
    ];

    const formatPhoneNumber = (phoneNumber) => {
        // Remove all non-digit characters from the phone number
        phoneNumber = phoneNumber.replace(/\D/g, '');

        // Check if the phone number length matches a valid format
        if (phoneNumber.length === 10) {
            // Format for 10-digit phone numbers (e.g., 1234567890)
            return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else if (phoneNumber.length === 11) {
            // Format for 11-digit phone numbers (e.g., 11234567890)
            return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
        } else if (phoneNumber.length === 12) {
            // Format for 11-digit phone numbers (e.g., 11234567890)
            return phoneNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
        } else if (phoneNumber.length === 13) {
            // Format for 11-digit phone numbers (e.g., 11234567890)
            return phoneNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
        } else {
            // If the phone number doesn't match any expected format, return as is
            return phoneNumber;
        }
    }

    //Filtrar
    const onChangeFilter = (e) => {
        setFilterText(e.target.value);
    }

    const handleClearFilter = () => {
        setFilterText('');
        setFilas(listado);
    }

    const handleEnviar = item => {
        dispatch(enviarInvitacion(item._id));
    }

    const handleEnviarManual = item => {

        let texto_enviar = '¡Hola! Esperemos puedas acompañarnos en este día tan importante en nuestras vidas. Para confirmar tu asistencia, ingresa el código ' + item.codigo + ' en la página ' + item.ruta;

        if(item.numero != '' && item.numero != null){
            let numero_enviar = '';

            if(item.numero.length > 10){
                numero_enviar = item.numero.substr(0, item.numero.length - 10) + '1' + item.numero.substr(item.numero.length - 10);
            }else{
                numero_enviar = '521' + item.numero.substr(item.numero.length - 10);
            }

            let win = window.open('https://api.whatsapp.com/send?text=' + texto_enviar + '&phone=+' + numero_enviar, '_blank');
            win.focus();
        }else{
            //navigator.clipboard.writeText(texto_enviar);
            dispatch(setAlert('No tiene un teléfono asignado.', 'warning'));
        }
    }

    const handleEliminar = item => {
        setEliminacionModal(true);
        setEliminarData({
            ...eliminarData,
            id: item._id
        });
    }

    const handleEliminacion = () => {
        dispatch(eliminarInvitacion(eliminarData.id));
        setEliminacionModal(false);
        setEliminarData({
            id: null
        });
    }

	useEffect(() => {
        dispatch(listarInvitaciones());
    }, []);

    useEffect(() => {
        setFilas(listado);
    }, [listado]);

    useEffect(() => {
        if(listado){
            setFilas(listado.filter(invitacion => 
                (invitacion.codigo && invitacion.codigo.toLowerCase().includes(filterText.toLowerCase())) || 
                (invitacion.referencia && invitacion.referencia.toLowerCase().includes(filterText.toLowerCase())) || 
                (invitacion.numero && invitacion.numero.includes(filterText))
            ));
        }
    }, [filterText]);
    
    return (
    	<Fragment>

    		{/* Modal de eliminación */}
            <Modal show={eliminacionModal} onHide={() => {
                setEliminacionModal(false);
                setEliminarData({
                    id: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar invitación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Deseas eliminar esta invitación?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleEliminacion}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

			<div className="container">
				<div className="row">
					<div className="col-md-8 offset-md-2 text-center heading-section mt-3">
						<h2 style={styles.titulo_seccion}>Administra tu lista de invitados y visualiza sus confirmaciones</h2>
					</div>
					<div className="col-md-8 offset-md-2 text-center heading-section">
                        <Link to="/invitaciones_listado/nuevo" className="btn btn-primary text-right" style={styles.btn_tabla}>Nueva invitación</Link>
					</div>
				</div>
			</div>
            
			<div className="container_admin">
				{
                    listado?.length > 0 ? 
                        <Fragment>
                            <div className="col-md-12 form-group text-left">
                                <label className="font-weight-bold">Búsqueda general</label>
                                <div class="input-group">
                                    <input type="text" className="form-control" placeholder="Buscar por código, referencia o teléfono" value={filterText} onChange={onChangeFilter}></input>
                                    <div class="input-group-append">
                                        <button class="btn btn-danger" type="button" onClick={handleClearFilter}><i className="icon-cancel-circle"></i></button>
                                    </div>
                                </div>
                            </div>

                            <DataTable 
                                columns={columns}
                                data={filas}
                                pagination
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                paginationComponentOptions={paginationOptions}
                                highlightOnHover={true}
                            />
                        </Fragment>
                    :
                        <div className="text-center">Aún no has agregado invitados a tus XV años 😱</div>
                }
			</div>
	    </Fragment>
    )
}

export default InvitacionesListado;