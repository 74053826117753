import React, { Fragment, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import ReactAudioPlayer from 'react-audio-player';

import { setAlert } from '../../Redux/actions/alert';
import { confirmarInvitacion } from '../../Redux/actions/invitaciones';

const styles = {
    contenedor_inicial: {
        marginTop: '20px'
    },
    fh5co_cover: {
    	backgroundImage: "url('/assets/images/cover_bg_1.jpg')"
    },
	fh5co_cover_2: {
    	backgroundImage: "url('/assets/images/cover_bg_2.jpg')"
    },
    check_zone: {
        display: 'flex',
        alignItems: 'center'
    }
}

const Inicio = () => {

	const dispatch = useDispatch();

	const [direcciones, setDirecciones ] = useState({
        ceremonia: 'https://maps.app.goo.gl/odKwrqw4kksoSkA19',
        recepcion: 'https://maps.app.goo.gl/GF4s6nnDKmqGXohu9'
    });

	const [confirmacionModal, setConfirmacionModal] = useState(false);

	const [confirmarData, setConfirmarData ] = useState({
		id_compra: process.env.REACT_APP_ID_COMPRA,
        codigo: '',
        adultos: [],
        ninos: []
    });

	/* Ubicacion */
	const onOpenAddress = (direccion) => {
		window.open(direccion, '_blank');
    }
	
	/* Confirmar */
    const onChangeCodigo = (e) => {
        setConfirmarData({
            ...confirmarData, [e.target.name]: e.target.value.trim()
        });
    }

	const handleAbrirConfirmar = async () => {

    	// hacemos el request
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ 'codigo': confirmarData.codigo, 'id_compra': process.env.REACT_APP_ID_COMPRA });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitaciones/boletos`, body, config);

			console.log(res.data.invitacion);

            if (res.data.success) {
				setConfirmarData({
					...confirmarData,
				    adultos: res.data.invitacion.adultos,
				    ninos: res.data.invitacion.ninos
				});

                setConfirmacionModal(true);
            } else {
                setAlert('Algo salió mal, inténtalo más tarde', 'danger')
            }
        } catch (error) {
			console.log(error);
            if(error.response){
	            if (error.response.data.errors) {
	                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
	            }else{
	                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
	            }
	        }else{
	            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
	        }
        }
    }

	const listadoAdultos = (boletos) => {
		if(boletos.length > 0){
			const items = [];

			items.push(
    	 		<div className="col-12" key={0}>
                    <p><b>Adultos</b></p>
                </div>
            );

			for (var i = 0; i < boletos.length; i++) {
				let indicador = i + 1;

        	 	items.push(
        	 		<div className="col-6 mb-2" style={styles.check_zone} key={indicador}>
                        <div className="form-check">
                            <input type="checkbox" id={'check-adulto-' + indicador} onChange={e => handleCheckboxAdulto(e)} checked={boletos[i].status}></input>
                            <label className="form-check-label" htmlFor={'check-adulto-' + indicador}>{boletos[i].nombre ? boletos[i].nombre : 'Invitado(a)'}</label>
                        </div>
                    </div>
                );
            }

        	return (
	        	<div className="row">
		            {items}
	        	</div>
        	);
        }else{
        	return (
                <div></div>
        	);
        }
    }

    const listadoNinos = (boletos) => {
		if(boletos.length > 0){
			const items = [];

			items.push(
    	 		<div className="col-12 mt-4" key={0}>
                    <p><b>Niños</b></p>
                </div>
            );

			for (var i = 0; i < boletos.length; i++) {
				let indicador = i + 1;

        	 	items.push(
        	 		<div className="col-6 mb-2" style={styles.check_zone} key={indicador}>
                        <div className="form-check">
                            <input type="checkbox" id={'check-nino-' + indicador} onChange={e => handleCheckboxNino(e)} checked={boletos[i].status}></input>
                            <label className="form-check-label" htmlFor={'check-nino-' + indicador}>{boletos[i].nombre ? boletos[i].nombre : 'Invitado(a)'}</label>
                        </div>
                    </div>
                );
            }

        	return (
	        	<div className="row">
		            {items}
	        	</div>
        	);
        }else{
        	return (
                <div></div>
        	);
        }
    }

    const handleCheckboxAdulto = ({ target }) => {
    	let adultos_actual = confirmarData.adultos;

    	let posicion_adulto = parseInt(target.id.split('-')[2]) - 1;

		adultos_actual[posicion_adulto].status = target.checked;

        setConfirmarData({
			...confirmarData, 
			adultos: adultos_actual
		});
    }

    const handleCheckboxNino = ({ target }) => {
    	let ninos_actual = confirmarData.ninos;

    	let posicion_nino = parseInt(target.id.split('-')[2]) - 1;

		ninos_actual[posicion_nino].status = target.checked;

        setConfirmarData({
			...confirmarData, 
			ninos: ninos_actual
		});
    }

    const handleGuardarConfirmar = async () => {
        await dispatch(confirmarInvitacion(confirmarData));
        await handleCerrarConfirmar();
    }

	const handleCerrarConfirmar = async () => {
        await setConfirmacionModal(false);
        await setConfirmarData({
        	...confirmarData,
            codigo: '',
            adultos: [],
	        ninos: []
        });
    }
    
    return (
    	<Fragment>

			<ReactAudioPlayer
				src="/assets/audio/song.mp3"
				className="song"
				autoPlay={false}
				controls={false}
			/>

    		{/* Modal de confirmación */}
            <Modal show={confirmacionModal} centered onHide={() => { handleCerrarConfirmar() }}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Selecciona los boletos a confirmar:</p>
                    {
	                    listadoAdultos(confirmarData.adultos)
	                }
	                {
	                    listadoNinos(confirmarData.ninos)
	                }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleGuardarConfirmar}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

			<div id="fh5co-couple" className="duidui">
				<div className="container" style={styles.contenedor_inicial}>
					<div className="row row-bottom-padded-md animate-box">
						<div className="col-md-12 text-center d-flex">
							<div className="col-md-12 col-sm-12 col-12 px-4">
								<h3 className="dia_fecha">Sábado</h3>
								<h1 className="color_dorado_oscuro numero_fecha">10</h1>
								<h2 className="mes_fecha">Agosto - 2024</h2>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 mb-3 animate-box">
							<div className="row animate-box">
								<div className="col-md-8 offset-md-2 text-center heading-section mb-0">
									<h2 className="color_dorado_oscuro">Mis Padres</h2>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 text-center animate-box">
									<div className="groom-men">
										<h3>Laura Patricia López Jiménez</h3>
									</div>
								</div>
								<div className="col-md-6 text-center animate-box">
									<div className="groom-men">
										<h3>Pedro González Cruz ✝</h3>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 mb-3 animate-box">
							<div className="row animate-box">
								<div className="col-md-8 offset-md-2 text-center heading-section mb-0">
									<h2 className="color_dorado_oscuro">Mis Padrinos</h2>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 text-center animate-box">
									<div className="groom-men">
										<h3>Hilda Rocio López Jiménez</h3>
									</div>
								</div>
								<div className="col-md-6 text-center animate-box">
									<div className="groom-men">
										<h3>José de Jesús Ramírez López</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-countdown">
				<div className="row">
					<div className="col-md-8 offset-md-2 text-center animate-box mb-5">
					<i className="fa-solid fa-calendar-days icono_titulo color_dorado_oscuro"></i>
					</div>
					<div className="col-md-8 offset-md-2 text-center animate-box">
						<p className="countdown">
							<span id="days" className="color_dorado_oscuro"></span>
							<span id="hours" className="color_dorado_oscuro"></span>
							<span id="minutes" className="color_dorado_oscuro"></span>
							<span id="seconds" className="color_dorado_oscuro"></span>
						</p>
					</div>
				</div>
			</div>

			<div id="fh5co-when-where" className="duidui">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2 className="color_dorado_oscuro">Itinerario</h2>
						</div>
					</div>
					<div className="row row-bottom-padded-md">
						<div className="offset-md-3 col-md-6 text-center animate-box mt-4">
							<div className="wedding-events">
								<div className="desc">
									<div className="col-md-8 offset-md-2 text-center animate-box mb-5">
										<i className="fa-solid fa-church icono_titulo color_dorado_oscuro"></i>
									</div>
									<h3 className="color_dorado_oscuro" style={styles.btn_direccion} onClick={() => onOpenAddress(direcciones.ceremonia)}>Ceremonia Religiosa</h3>
									<h4 className="color_dorado_oscuro"><strong>7:00 PM</strong></h4>
									<p>Capilla del Sagrado Corazón de Jesús en Av. Guadalupe Gonzalez 1113, Residencial Campestre Los Fresnos, C.P. 20328</p>
									<button type="button" className="btn btn-primary btn-block" onClick={() => onOpenAddress(direcciones.ceremonia)}>Ver ubicación</button>
								</div>
							</div>
						</div>
						<div className="offset-md-3 col-md-6 text-center animate-box mt-4">
							<div className="wedding-events">
								<div className="desc">
									<div className="col-md-8 offset-md-2 text-center animate-box mb-5">
										<i className="fa-solid fa-champagne-glasses icono_titulo color_dorado_oscuro"></i>
									</div>
									<h3 className="color_dorado_oscuro" style={styles.btn_direccion} onClick={() => onOpenAddress(direcciones.recepcion)}>Recepción</h3>
									<h4 className="color_dorado_oscuro"><strong>8:30 PM</strong></h4>
									<p>Jardín Las Palmeras en Antiguo Camino a San Ignacio Km. 1, C.P. 20326</p>
									<button type="button" className="btn btn-primary btn-block" onClick={() => onOpenAddress(direcciones.recepcion)}>Ver ubicación</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-when-where" className="fh5co-section-rose">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center animate-box mb-5">
							<i className="fa-solid fa-user-tie icono_titulo color_dorado_oscuro"></i>
						</div>
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2 className="color_dorado_oscuro">Código de vestimenta</h2>
						</div>
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<p>Se sugiere el uso de vestimenta formal, evitar el uso de gris (reservado para la quinceañera).</p>
						</div>
					</div>

				</div>
			</div>

			<div id="fh5co-gallery">
				<div className="container">
					<div className="row">
						<div className="col-md-8 offset-md-2 text-center heading-section animate-box">
							<h2 className="color_dorado_oscuro">Galería</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="gallery animate-box">
								<a className="gallery-img image-popup image-popup" href="/assets/images/galeria/1.jpg"><img src="/assets/images/galeria/1.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/7.jpg"><img src="/assets/images/galeria/7.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/8.jpg"><img src="/assets/images/galeria/8.jpg" className="img-fluid" alt="" /></a>
							</div>
						</div>
						<div className="col-md-4">
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/6.jpg"><img src="/assets/images/galeria/6.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/2.jpg"><img src="/assets/images/galeria/2.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/5.jpg"><img src="/assets/images/galeria/5.jpg" className="img-fluid" alt="" /></a>
							</div>
						</div>
						<div className="col-md-4">
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/3.jpg"><img src="/assets/images/galeria/3.jpg" className="img-fluid" alt="" /></a>
							</div>
							<div className="gallery animate-box">
								<a className="gallery-img image-popup" href="/assets/images/galeria/4.jpg"><img src="/assets/images/galeria/4.jpg" className="img-fluid" alt="" /></a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-started" className="duidui text-center">
				{/* <div className="overlay"></div> */}
				<div className="container">
					<div className="row animate-box">
						<div className="col-md-8 offset-md-2 text-center animate-box mb-5">
							<i className="fa-solid fa-check-to-slot icono_titulo color_dorado_oscuro"></i>
						</div>
						<div className="col-md-8 offset-md-2 text-center heading-section">
							<h2 className="color_dorado_oscuro">¿Deseas confirmar tu asistencia?</h2>
							<p>Da click en el siguiente botón.</p>
						</div>
					</div>
					<div className="row animate-box">
						<div className="col-md-10 offset-md-1">
							<div className="offset-md-3 col-md-6">
								<a className="btn btn-primary btn-block" href="https://api.whatsapp.com/send?phone=524492747295" target="_blank">Confirmar</a>
							</div>
						</div>
					</div>
				</div>
			</div>
	    </Fragment>
    )
}

export default Inicio;