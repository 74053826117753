import React from 'react';

import { useLocation } from 'react-router-dom';

const styles = {
    fh5co_cover: {
    	backgroundImage: "url('/assets/images/cover_bg_1.jpg')"
    }
}

const Image = () => {

	const location = useLocation();

    return (
        <div className="fh5co-hero" data-section="home">
			<div className="fh5co-overlay"></div>
			<div className="duidui fh5co-cover text-center" data-stellar-background-ratio="0.5" style={ styles.fh5co_cover }>
				<div className="display-t">
					<div className="display-tc">
						<div className="container">
							<div className="col-md-10 offset-md-1">
								<div className="animate-box">
									<h1 className="color_dorado_claro">{ location.pathname === '/' ? 'Mis XV años' : 'Laura Alejandra' }</h1>
									<h2 className="color_dorado_claro">
										{
									        {
									          	'/': 'Laura Alejandra',
										      	'/detalles': '¿Cuando y donde?',
										      	'/invitados': 'Invitados',
										      	'/regalos': 'Regalos',
										      	'/galeria': 'Galería',
										      	'/blog': 'Blog'
									        }[location.pathname] || 'Mis XV años'
							      		}
									</h2>
									{
										location.pathname === '/' ? <p className="color_dorado_claro"><span>10.08.2024</span></p> : ''
									}
								</div>
								<p className="frase color_dorado_claro mt-5">Hay momentos en la vida que son irrepetibles, pero al compartirlos con las personas que más quieres, estos se vuelven inolvidables...</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Image;